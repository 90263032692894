.mainPageFooter {
  display: flex;
  flex-direction: column;

  & .imageFooter {
    background-image: $footerImage;
    height: 510px;
    @extend %backgroundImage;
    display: flex;
    justify-content: center;
    align-items: center;

    & .footerBox {
      width: 55%;
      max-width: 600px;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding: 25px 0px;

      & .description {
        text-align: center;
        width: 80%;
        padding-bottom: 20px;
      }

      & .onlineStores {
        display: flex;

        & .androidStore,
        & .appleStore {
          width: 130px;
          height: 35px;
          @extend %backgroundImage;
          background-size: contain;
          cursor: pointer;
        }

        & .androidStore {
          background-image: $androidStore;
        }

        & .appleStore {
          background-image: $appleStore;
        }
      }
    }
  }

  & .footerPrivacy {
    min-height: 150px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & .description {
      font-size: 14px;
      color: white;
      width: 50%;
      text-align: center;
    }

    & .termsBox {
      margin-top: 10px;
      font-size: 12px;
      color: #dcdcdc !important;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;
      line-height: 18px;

      & .termsLink {
        text-decoration: underline;
        cursor: pointer;
      }

      & a {
        color: #dcdcdc !important;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .mainPageFooter {
    & .imageFooter {
      & .footerBox {
        width: 80%;
      }
    }

    & .footerPrivacy {
      padding: 15px 0px;

      & .description {
        width: 75%;
      }
    }
  }
}
