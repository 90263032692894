.mainPageBody {
  // height: 1400px;

  display: flex;
  flex-direction: column;

  // UPPER BODY
  & .upperBody {
    padding: 55px 215px 65px;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    & .container {
      display: flex;
      flex-direction: row;
      width: 100vw;
      margin-bottom: 50px;
      justify-content: center;

      & .avatar {
        min-height: 300px;
        max-height: 300px;
        min-width: 275px;
        max-width: 275px;

        background-image: $avatar;
        @extend %backgroundImage;
        background-size: contain;
      }

      & .bodyText {
        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;

        padding-left: 50px;
        max-width: calc(100vw - 350px);

        & .logo {
          width: 310px;
          height: 55px;

          background-image: $pageLogoExtended;
          @extend %backgroundImage;
          background-size: contain;
        }

        & .description {
          width: 525px;
          padding-top: 40px;
          text-align: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
        }
      }
    }

    & .scrollDown {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      & .text {
        font-family: $boldFont;
        font-size: 14px;
        color: $descriptionColor;
        margin-bottom: 5px;
      }

      & .icon {
        height: 10px;
        width: 20px;
        background-image: $chevronIcon;
        @extend %backgroundImage;

        position: absolute;
        animation: bounce;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
      }
    }
  }

  // BOTTOM BODY
  & .bottomBody {
    display: flex;
    flex-direction: column;

    & .mediumCase {
      // max-height: 445px;
      padding: 100px 0px 170px 125px;
      overflow-y: visible;

      background-color: $bodyMediumColor;

      display: flex;
      flex-direction: row;
      justify-content: center;

      & .box {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1280px;
      }

      & .textBox {
        display: flex;
        flex-direction: column;
        padding-top: 80px;

        & .title {
          margin-bottom: 25px;
        }

        & .title,
        & .description {
          max-width: 300px;
          min-width: 300px;
        }
      }

      & .bodyImage {
        height: 10px;
        width: 720px;

        & .image {
          height: 475px;
          background-image: $bodyImage;

          @extend %backgroundImage;
        }
      }
    }

    & .bodyBottom {
      // padding: 165px 0px 105px;
      height: 180px;
      display: flex;
      justify-content: center;

      & .description {
        width: 630px;

        text-align: center;
      }
    }
  }
}

@keyframes bounce {
  0% {
    top: 20px;
  }
  50% {
    top: 30px;
  }
  100% {
    top: 20px;
  }
}

@media (max-width: 1000px) {
  .mainPageBody {
    & .container {
      .bodyText {
        padding-left: 25px !important;
        .description {
          width: auto !important;
        }
      }
    }
    .mediumCase {
      padding: 0 !important;
      & .bodyImage {
        height: 300px !important;
        width: 100vw !important;
        & .image {
          height: 300px !important;
          width: 100vw !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .mainPageBody {
    // UPPER BODY
    & .upperBody {
      padding: 50px 0px 70px;

      & .container {
        flex-direction: column;
        align-items: center;
        margin-bottom: 0px;

        & .avatar {
          margin-bottom: 50px;
        }

        & .bodyText {
          width: 100vw;
          max-width: unset;
          padding-left: 0px !important;
          & .logo {
            width: 100vw;
            height: 35px;
          }

          & .description {
            width: 80% !important;
          }
        }
      }

      & .scrollDown {
        display: none;
      }
    }

    // BOTTOM BODY
    & .bottomBody {
      & .mediumCase {
        padding: 0px;
        flex-direction: column;

        & .textBox {
          padding: 80px 20px 50px;
        }

        & .bodyImage {
          height: 50vh;
          width: 100vw;
          & .image {
            height: 50vh;
            width: 100vw;
          }
        }
      }

      & .bodyBottom {
        display: none;
      }
    }
  }
}
