$pageBackground: #fff;
$bodyMediumColor: rgb(249, 249, 249);

$accentColor: #c30f2e;
// Texts

$titleSize: 30px;
$titleColor: rgb(45, 45, 45);

$descriptionSize: 16px;
$descriptionColor: #000;

$footerTextSize: 16px;
$footerTextColor: #fff;
