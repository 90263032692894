.mainPage {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  overflow-y: auto;
  overflow-x: hidden;

  background-color: $pageBackground;
}
