$images-path: "/assets/images/";

$pageLogo: url("#{$images-path}pageLogo.png");
$headerImage: url("#{$images-path}headerImage.png");
$headerImageMobile: url("#{$images-path}headerImageMobile.png");

$avatar: url("#{$images-path}avatar.png");
$pageLogoExtended: url("#{$images-path}pageLogoExtended.png");
$bodyImage: url("#{$images-path}bodyImage.png");

$footerImage: url("#{$images-path}footerImage.png");

$appleStore: url("#{$images-path}appleStore.png");
$androidStore: url("#{$images-path}androidStore.png");

$chevronIcon: url("#{$images-path}chevron.png");

%backgroundImage {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
