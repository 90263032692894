.termsPage {
  overflow-y: visible;
  overflow-x: visible;
  padding: 20px;

  flex: 1;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.terms_page {
  margin-bottom: 20px;
}

.page_loading {
  color: #fff;
}

.terms_page:last-child {
  margin-bottom: 0px;
}

html {
  background-color: #2e2e2e;
}

// Override
.react-pdf__Page {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.react-pdf__Page__canvas {
  height: 100% !important;
  width: 50% !important;
}

.react-pdf__Page__textContent {
  display: none;
  height: 0 !important;
  width: 0 !important;
}

.react-pdf__Page__annotations {
  display: none;
  height: 0 !important;
  width: 0 !important;
}

@media only screen and (max-width: 600px) {
  .react-pdf__Page__canvas {
    height: 100% !important;
    width: 100% !important;
  }
}
