// Common

@import "./common/reset";
@import "./common/devices";
@import "./common/fonts";
@import "./common/variables";
@import "./common/images";

// Components scss
@import "../components/Dropdown/Dropdown.scss";

// Pages scss
@import "../pages/MainPage/MainPage.scss";
@import "../pages/MainPage/partials/MainPageHeader/MainPageHeader.scss";
@import "../pages/MainPage/partials/MainPageUpper/MainPageUpper.scss";
@import "../pages/MainPage/partials/MainPageBody/MainPageBody.scss";
@import "../pages/MainPage/partials/MainPageFooter/MainPageFooter.scss";

// Privacy css
@import "../pages/PrivacyPage/PrivacyPage.scss";

// Terms of Use css
@import "../pages/TermsOfUsePage/TermsOfUsePage.scss";

body {
  font-family: $regularFont;
}

.title {
  font-family: $boldFont;
  font-size: $titleSize;
  color: $titleColor;
}

.description {
  font-family: $regularFont;
  font-size: $descriptionSize;
  color: $descriptionColor;
  line-height: 1.2;
}

.comingSoon {
  font-family: $mediumFont;
  color: $accentColor;
  font-size: 16px;
}

.d-none {
  display: none !important;
}

html {
  scroll-behavior: smooth;
  user-select: none;
}
