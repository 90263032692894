.dropdownLanguages {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;

  padding: 5px 0px;

  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);

  color: rgb(126, 126, 126);

  & .dropdownOptions {
    flex-direction: column;

    &.closed {
      display: none;
      height: 0px;
    }

    &.open {
      display: flex;
    }
  }

  & .dpItem {
    padding: 7.5px 20px;

    &.selected {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &.state {
        min-width: 100px;
      }

      &.text {
        flex: 1;
      }

      &.open {
        border-bottom: 1px solid rgb(247, 247, 247);

        & .iconDropdown {
          transform: rotate(180deg);
        }
      }

      & .iconDropdown {
        margin-left: 5px;
        height: 7px;
        width: 14px;

        background-image: $chevronIcon;
        @extend %backgroundImage;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .dropdownLanguages {
    & .dpItem {
      padding: 5px 10px;
      &.selected {
        &.state {
          min-width: 40px;
        }
        & .iconDropdown {
          height: 5px;
          width: 10px;
        }
      }
    }
  }
}
