$upperHeight: 520px;

.mainPageUpper {
  min-height: $upperHeight;
  max-height: $upperHeight;

  background-color: $bodyMediumColor;

  display: flex;
  flex-direction: column;
  align-items: center;
  @extend %backgroundImage;

  & .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  & .info {
    $pad: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: calc(#{$upperHeight} - calc(#{$pad} * 2));
    width: 45vw;
    gap: 1rem;
    padding: $pad 0px;

    .description {
      font-size: 1.2rem;
      text-align: center;
      width: 80%;
      white-space: break-spaces;
      font-weight: bold;
    }
  }

  & .bodyImage {
    height: $upperHeight;
    width: 55vw;

    & .image {
      height: $upperHeight;
      background-image: $bodyImage;

      @extend %backgroundImage;
    }
  }

  & .logoContainer {
    display: flex;
    justify-content: center;

    & .logo {
      height: 75px;
      width: 75px;
      background-image: $pageLogo;
      @extend %backgroundImage;
    }
  }

  & .messageContainer {
    display: flex;
    width: 45%;
    flex-direction: column;

    & .title,
    & .description {
      text-align: center;
    }

    & .title {
      color: $descriptionColor;
      margin-bottom: 20px;
    }

    & .description {
      font-size: 18px;
    }
  }

  & .onlineStores {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    padding-top: 1rem;

    & .appleStore,
    & .androidStore {
      height: 50px;
      width: 200px;
      cursor: pointer;
      @extend %backgroundImage;
      background-size: auto 100%;
    }

    & .appleStore {
      background-image: $appleStore;
    }
    & .androidStore {
      background-image: $androidStore;
    }
  }
  & .scrollDown {
    display: none;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    & .text {
      font-family: $boldFont;
      font-size: 14px;
      color: $descriptionColor;
      margin-bottom: 5px;
    }

    & .icon {
      height: 10px;
      width: 20px;
      background-image: $chevronIcon;
      @extend %backgroundImage;

      position: absolute;
      animation: bounce;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
    }
  }
}

@media (max-width: 1000px) {
  .mainPageUpper {
    & .bodyImage {
      display: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  .mainPageUpper {
    min-height: 95vh;
    max-height: 95vh;
    align-items: center;
    position: relative;

    & .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100vw;
    }

    & .info {
      $pad: 75px;
      padding-top: $pad * 2;
      padding-bottom: $pad;
      width: unset;
      justify-content: flex-start;
      height: calc(100vh - calc(#{$pad} * 2));
    }

    & .bodyImage {
      display: none;
    }

    & .logoContainer {
      padding-bottom: 30px;
    }

    & .messageContainer {
      width: 75%;
      padding-bottom: 0%;
    }

    & .scrollDown {
      display: flex;
      position: absolute;
      bottom: 7.5%;
      &.apple {
        bottom: 12.5%;
      }
    }
  }
}

@keyframes bounce {
  0% {
    top: 20px;
  }
  50% {
    top: 30px;
  }
  100% {
    top: 20px;
  }
}
